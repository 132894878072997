@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip {
    clip: rect(0, auto, auto, 0);
  }
}

@layer base {
  input[type='number'].appearance-none::-webkit-outer-spin-button,
  input[type='number'].appearance-none::-webkit-inner-spin-button,
  input[type='number'].appearance-none {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .input-style {
    @apply bg-light_gray text-white hover:text-black focus:text-black focus:bg-gray-200 hover:bg-gray-200;
  }
  /* ... */
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}
